<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { configTypeList } from '@/utils/textFile'

export default {
  name: 'projectPageType',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmShowSpecialConfig/page',
      current: 1,
      paramsValue: {
        showId: this.$route.query.showId,
      },
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'configName',
          title: '分类名称',
          align: 'left',
        },
        {
          dataIndex: 'titleImage',
          title: '图片',
          align: 'left',
          width: '40%',
          customRender: (text, records) => {
            let aImg = text?.split(',') || []
            return (
              <div class="product-order-item">
                {aImg.map((item, index) => {
                  return <img src={item} key={index} />
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '商品管理',
                onClick: () => {
                  this.$router.push({
                    path: '/marketingCenter/projectPageGoods',
                    query: {
                      configId: records.id,
                      configType: records.configType,
                    },
                  })
                },
              },
              {
                name: '编辑',
                onClick: () => {
                  let obj = Object.assign({}, records)
                  this.handlePopup(obj)
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/farmShowSpecialConfig',
                    params: {
                      id: records.id,
                    },
                  })
                },
              },
            ]
          },
        },
      ]
    },
    getDrawerForm(source = { sort: 50 }, formData) {
      const form = [
        {
          form: [
            {
              name: '类型',
              type: 'select',
              cols: 24,
              key: 'configType',
              typeData: configTypeList,
              rules: [
                {
                  required: true,
                  type: 'string',
                  message: '请选择类型',
                },
              ],
            },
            {
              name: '分类名称',
              key: 'configName',
              labelAlign: 'right',
              type: 'input',
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '排序号（默认50）',
              key: 'sort',
              labelAlign: 'right',
              type: 'inputNumber',
            },
            {
              name: '选中',
              labelAlign: 'right',
              type: 'iconUpload',
              typeData: [
                {
                  maxLength: 1,
                  key: 'checkImage',
                  desc: '@2x   xhdpi',
                },
              ],
            },
            {
              name: '未选中',
              labelAlign: 'right',
              type: 'iconUpload',
              typeData: [
                {
                  maxLength: 1,
                  key: 'unCheckImage',
                  desc: '@2x   xhdpi',
                },
              ],
            },
            {
              name: '标题',
              labelAlign: 'right',
              type: 'iconUpload',
              typeData: [
                {
                  maxLength: 1,
                  key: 'titleImage',
                  desc: '@2x   xhdpi',
                },
              ],
            },
          ],
        },
      ]
      return form
    },
    handlePopup(dataSource = { sort: 50 }) {
      let that = this
      apiTool.showDrawer({
        title: '设置',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(dataSource, formData),
          form: dataSource,
          //   formProps: {
          //     labelCol: { span: 3 },
          //     wrapperCol: { span: 20 }
          //   }
        },
        success: ({ data, setHidden }) => {
          if (!data.configName) {
            this.$message.error('请填写分类名称')
            return
          } else {
            api.command[data.id ? 'edit' : 'create']
              .call(that, {
                url: '/farmShowSpecialConfig',
                params: { ...data, showId: this.$route.query.showId },
              })
              .then(setHidden)
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handlePopup()
          },
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style scoped lang="less">
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
